var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('h3', {
    staticClass: "page-title mb-7 font-weight-bold"
  }, [_vm._v("車両一覧")]), _c('v-row', {
    staticClass: "no-gutters mb-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "キーワード検索",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.getDataFromApi
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "slot": "append",
      "color": "blue",
      "small": "",
      "elevation": "0",
      "height": "23"
    },
    on: {
      "click": _vm.getDataFromApi
    },
    slot: "append"
  }, [_vm._v(" 検索 ")])], 1)], 1), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    staticClass: "btn-new",
    attrs: {
      "height": "30",
      "color": "#CFD3FE",
      "depressed": "",
      "min-width": "100",
      "to": "/management/car/register"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" 車両情報登録 ")], 1)], 1)], 1), _vm.pagination && _vm.cars ? _c('Table', {
    staticClass: "pb-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.cars,
      "total-records": _vm.pagination.records_total,
      "number-of-pages": _vm.pagination.total_pages,
      "footer": false,
      "loading": _vm.loading,
      "options": _vm.options,
      "items-per-page": 50
    },
    on: {
      "click:row": _vm.edit,
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(item.id))])];
      }
    }, {
      key: "item.class_name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(item.car_class))])];
      }
    }, {
      key: "item.brand",
      fn: function fn(_ref3) {
        _objectDestructuringEmpty(_ref3);

        return [_c('span', [_vm._v("-")])];
      }
    }, {
      key: "item.miles",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("number")(item.miles)))])];
      }
    }, {
      key: "item.year",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(item.date_of_purchase))])];
      }
    }, {
      key: "item.plate_number",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(item.number_plate))])];
      }
    }, {
      key: "item.actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary mr-2",
            "fab": "",
            "x-small": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      },
      proxy: true
    }], null, false, 579804405)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }