<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <h3 class="page-title mb-7 font-weight-bold">車両一覧</h3>
      <v-row class="no-gutters mb-6" align="center">
        <v-col cols="4">
          <v-text-field
            placeholder="キーワード検索"
            v-model="search"
            @input="getDataFromApi"
            dense
            outlined
            hide-details
          >
            <v-btn
              slot="append"
              color="blue"
              small
              @click="getDataFromApi"
              class="white--text"
              elevation="0"
              height="23"
            >
              検索
            </v-btn>
          </v-text-field>
        </v-col>
        <v-col class="text-right">
          <v-btn
            height="30"
            class="btn-new"
            color="#CFD3FE"
            depressed
            min-width="100"
            to="/management/car/register"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            車両情報登録
          </v-btn>
        </v-col>
      </v-row>
      <Table
        v-if="pagination && cars"
        :headers="headers"
        :items="cars"
        :total-records="pagination.records_total"
        :number-of-pages="pagination.total_pages"
        :footer="false"
        class="pb-3"
        @click:row="edit"
        :loading="loading"
        :options.sync="options"
        :items-per-page="50"
      >
        <template v-slot:item.id="{ item }">
          <span>{{ item.id }}</span>
        </template>
        <template v-slot:item.class_name="{ item }">
          <span>{{ item.car_class }}</span>
        </template>
        <template v-slot:item.brand="{}">
          <span>-</span>
        </template>
        <template v-slot:item.miles = "{item}">
          <span>{{item.miles | number}}</span>
        </template>
        <template v-slot:item.year="{ item }">
          <span>{{ item.date_of_purchase }}</span>
        </template>
        <template v-slot:item.plate_number="{ item }">
          <span>{{ item.number_plate }}</span>
        </template>
        <template v-slot:item.actions>
          <v-btn color="primary mr-2" fab x-small>
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <!--        <v-btn color="error" fab x-small>-->
          <!--          <v-icon small>mdi-delete</v-icon>-->
          <!--        </v-btn>-->
        </template>
      </Table>
      <!-- <h1>{{cars}}</h1> -->
    </v-container>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
export default {
  name: 'List',
  components: { Table },
  data() {
    return {
      headers: [
        { text: 'ID', align: 'center', value: 'id' },
        {
          text: '車種名',
          align: 'center',
          sortable: false,
          value: 'class_name'
        },
        {
          text: '車両番号',
          align: 'center',
          value: 'plate_number',
          sortable: false
        },
        {
          text: '購入日',
          align: 'center',
          value: 'date_of_purchase',
          sortable: false
        },
        {
          text: '購入時距離',
          align: 'center',
          value: 'miles',
          sortable: false
        },
        {
          text: '次回車検日',
          align: 'center',
          value: 'date_of_next_investigation',
          sortable: false
        },
        // {
        //   text: 'year',
        //   align: 'center',
        //   value: 'year',
        //   sortable: false
        // },
        {
          text: '',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      loading: false,
      search: '',
      options: {}
    }
  },
  computed: {
    cars() {
      return this.$store.getters.allCars
    },
    pagination() {
      return this.$store.getters.pagination
    }
  },
  filters: {
    number(value) {
      const withoutCommas = value.split(',').join('')
      const parse = parseInt(withoutCommas)
      return parse.toLocaleString()
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    async getDataFromApi() {
      this.loading = true
      let params = {
        search: this.search,
        paginate: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0]
      }
      await this.$store.dispatch('CAR_GET_ALL', params)
      this.loading = false
    },
    edit(param1, param2) {
      this.$router.push({
        name: 'CarManagementEdit',
        params: {
          id: param2.item.id
        }
      })
    }
  }
}
</script>

<style src="./List.scss" lang="scss" scoped></style>
